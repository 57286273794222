import React, { useState, useEffect } from "react";

import { Format, FormatOptions } from "~/lib/format";
import classNames from "classnames";

export type FormatOptionsValues = Record<
  string,
  string | number | boolean | null
>;
type Errors = Record<string, string | null>;

export function getFormatDefaultOptions(
  format: Format,
  type: "read" | "write"
): FormatOptionsValues {
  const options = type === "read" ? format.readOptions : format.writeOptions;
  if (options === null) {
    return {};
  }
  const values: FormatOptionsValues = {};
  for (const [key, option] of Object.entries(options)) {
    if (option.type === "hidden") {
      values[key] = option.value;
    } else if (option.type === "select" && option.default === "") {
      values[key] = null;
    } else {
      values[key] = option.default ?? null;
    }
  }
  return values;
}

export function validateFormatOptions(
  options: FormatOptions,
  newValues: FormatOptionsValues
): Errors {
  const newErrors: Errors = {};
  for (const [key, option] of Object.entries(options)) {
    const value = newValues[key];

    if (option.type === "select") {
      const selectOption = option;
      if (selectOption.required && (value === null || value === "")) {
        newErrors[key] = "This field is required";
      }
      if (
        selectOption.options.length > 0 &&
        !selectOption.options.some((o) => o.value === value)
      ) {
        newErrors[
          key
        ] = `Invalid value, please select one of ${selectOption.options
          .map((o) => `\`${o.value}\``)
          .join(", ")}`;
      }
    } else if (option.type === "text") {
      const textOption = option;
      if (textOption.required && !value) {
        newErrors[key] = "This field is required";
      }
    } else if (option.type === "number") {
      const numberOption = option;
      if (numberOption.required && value === null) {
        newErrors[key] = "This field is required";
      } else if (typeof value === "number") {
        const numberValue = value;
        if (numberOption.min !== undefined && numberValue < numberOption.min) {
          newErrors[key] = `Value must be at least ${numberOption.min}`;
        }
        if (numberOption.max !== undefined && numberValue > numberOption.max) {
          newErrors[key] = `Value must be at most ${numberOption.max}`;
        }
      }
    } else if (option.type === "checkbox") {
      const checkboxOption = option;
      if (checkboxOption.required && !value) {
        newErrors[key] = "This field is required";
      }
    }
  }
  return newErrors;
}

function shallowEqual(a: FormatOptionsValues, b: FormatOptionsValues): boolean {
  if (Object.keys(a).length !== Object.keys(b).length) {
    return false;
  }
  for (const key in a) {
    if (a[key] !== b[key]) {
      return false;
    }
  }
  return true;
}

export function FormatForm(props: {
  format: Format;
  type: "read" | "write";
  values?: FormatOptionsValues;
  isDirty?: boolean;
  onChange: (values: FormatOptionsValues) => void;
  onValidate?: (isValid: boolean) => void;
}) {
  const options =
    props.type === "read"
      ? props.format.readOptions
      : props.format.writeOptions;

  if (options === null) {
    return null;
  }

  const { format, onValidate, onChange, isDirty } = props;
  const [values, setValues] = useState<FormatOptionsValues>(props.values ?? {});
  const [errors, setErrors] = useState<Errors>(
    validateFormatOptions(options, values)
  );
  const [dirtyFields, setDirtyFields] = useState<Set<string>>(new Set());

  useEffect(() => {
    const newValues = {
      ...getFormatDefaultOptions(format, props.type),
      ...values,
    };
    if (!shallowEqual(newValues, values)) {
      setValues(newValues);
      onChange(newValues);
    }
    const newErrors = validateFormatOptions(options!, newValues);
    setErrors(newErrors);
    onValidate?.(Object.keys(newErrors).length === 0);
  }, [format]);

  useEffect(() => {
    onValidate?.(Object.keys(errors).length === 0);
  }, [onValidate]);

  useEffect(() => {
    if (props.values === undefined) {
      return;
    }
    // we do not call onChange to avoid causing an infinite loop
    // with the parent component
    setValues(props.values);
    const newErrors = validateFormatOptions(options!, props.values);
    setErrors(newErrors);
    onValidate?.(Object.keys(newErrors).length === 0);
  }, [props.values]);

  useEffect(() => {
    if (isDirty === true) {
      setDirtyFields(new Set(Object.keys(options)));
    }
  }, [isDirty]);

  function updateValue(key: string, value: string | boolean | null) {
    const newValues = { ...values, [key]: value };
    setValues(newValues);
    setDirtyFields(new Set([...dirtyFields, key]));
    const newErrors = validateFormatOptions(options!, newValues);
    setErrors(newErrors);
    onValidate?.(Object.keys(newErrors).length === 0);
    onChange(newValues);
  }

  function fieldHasErrors(key: string) {
    return errors[key] && dirtyFields.has(key);
  }

  return (
    <>
      {Object.entries(options).map(([key, option]) => {
        if (option.type === "hidden") {
          return;
        }

        const { title, type, required } = option;

        const value = values[key];
        return (
          <div key={key} className="flex flex-col">
            {type === "select" ? (
              <>
                <label>
                  <span className="form-label inline-block">{title}</span>
                  <select
                    name={key}
                    className="form-control "
                    value={(value ?? "") as string}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        updateValue(key, null);
                      } else {
                        updateValue(key, e.target.value);
                      }
                    }}
                    required={required}
                  >
                    {option.options.map(({ value, label }) => (
                      <option key={value} value={value ?? ""}>
                        {label}
                      </option>
                    ))}
                  </select>
                </label>
                {errors[key] && dirtyFields.has(key) && (
                  <span className="pt-2 text-red-700">{errors[key]}</span>
                )}
              </>
            ) : type === "checkbox" ? (
              <>
                <label className="flex items-center">
                  <input
                    name={key}
                    type="checkbox"
                    className="peer"
                    checked={(value ?? false) as boolean}
                    onChange={(e) => {
                      updateValue(key, e.target.checked);
                    }}
                    required={required}
                  />
                  <span className="inline-block pl-2 text-gray-500 peer-checked:font-medium cursor-pointer">
                    {title}
                  </span>
                </label>
                {errors[key] && dirtyFields.has(key) && (
                  <span className="pt-2 text-red-700">{errors[key]}</span>
                )}
              </>
            ) : (
              <>
                <label>
                  <span className="form-label inline-block">{title}</span>
                  <input
                    name={key}
                    type="text"
                    value={(value ?? "") as string}
                    onChange={(e) => {
                      updateValue(key, e.target.value);
                    }}
                    required={required}
                    className={classNames("form-control  w-full", {
                      invalid: fieldHasErrors(key),
                    })}
                  />
                </label>
                {fieldHasErrors(key) && (
                  <span className="pt-2 text-red-700">{errors[key]}</span>
                )}
              </>
            )}
          </div>
        );
      })}
    </>
  );
}
