import { z } from "zod";
import RAW_FORMATS from "./formats.json";

const formatOptionSchema = z.union([
  z.object({
    type: z.literal("select"),
    default: z.string().optional().nullable(),
    options: z.array(
      z.object({ value: z.string().or(z.null()), label: z.string() })
    ),
    required: z.boolean(),
    title: z.string(),
  }),
  z.object({
    type: z.literal("text"),
    default: z.string().optional(),
    required: z.boolean(),
    title: z.string(),
  }),
  z.object({
    type: z.literal("number"),
    default: z.number().optional(),
    min: z.number().optional(),
    max: z.number().optional(),
    required: z.boolean(),
    title: z.string(),
  }),
  z.object({
    type: z.literal("checkbox"),
    default: z.boolean().optional(),
    required: z.boolean(),
    title: z.string(),
  }),
  z.object({
    type: z.literal("hidden"),
    value: z.union([z.string(), z.boolean()]),
  }),
]);

export const formatSchema = z.object({
  id: z.string(),
  title: z.string(),
  longTitle: z.string().optional(),
  metaTitle: z.string().optional(),
  extraFeatures: z.string().optional(),
  mime: z.string(),
  extension: z.string(),
  isPlainText: z.boolean().optional(),
  readOptions: z.record(formatOptionSchema).nullable(),
  writeOptions: z.record(formatOptionSchema).nullable(),
  extends: z.string().optional(),
});

const rawFormatSchema = formatSchema.or(
  formatSchema.partial().extend({
    extends: z.string(),
  })
);

export type Format = z.infer<typeof formatSchema>;
export type FormatOption = z.infer<typeof formatOptionSchema>;
export type FormatOptions = Record<string, FormatOption>;

export function getFormatByExtension(extension: string): Format | null {
  return FORMATS.find((format) => format.extension === extension) ?? null;
}

export function getFormatById(id: string): Format | null {
  return FORMATS.find((format) => format.id === id) ?? null;
}

const rawFormats = z.array(rawFormatSchema).parse(RAW_FORMATS);

export function formatSupportsRead(format: Format): boolean {
  return format.readOptions !== null;
}

export function formatSupportsWrite(format: Format): boolean {
  return format.writeOptions !== null;
}

export function formatHasReadOptions(format: Format): boolean {
  if (format.readOptions === null) {
    return false;
  }

  const nonHiddenOptions = Object.values(format.readOptions).filter(
    (option) => option.type !== "hidden"
  );

  return nonHiddenOptions.length > 0;
}

export function formatHasWriteOptions(format: Format): boolean {
  if (format.writeOptions === null) {
    return false;
  }

  return Object.values(format.writeOptions).some(
    (option) => option.type !== "hidden"
  );
}

export const FORMATS: Format[] = rawFormats.map((format) => {
  if ("extends" in format) {
    const parentFormat = rawFormats.find(
      (parentFormat) => parentFormat.id === format.extends
    );

    if (parentFormat === undefined || "extends" in parentFormat) {
      throw new Error(
        `Format ${format.id} extends ${format.extends} which does not exist or extends another format`
      );
    }

    const finalFormat: Format = {
      ...parentFormat,
      ...format,
      readOptions:
        format.readOptions === null || format.readOptions === undefined
          ? null
          : {
              ...parentFormat.readOptions,
              ...format.readOptions,
            },
      writeOptions:
        format.writeOptions === null || format.writeOptions === undefined
          ? null
          : {
              ...parentFormat.writeOptions,
              ...format.writeOptions,
            },
    };

    return finalFormat;
  }

  return format;
});

export const READ_FORMATS = FORMATS.filter(
  (format) => format.readOptions !== null
);

export const WRITE_FORMATS = FORMATS.filter(
  (format) => format.writeOptions !== null
);
