import { DataLayer } from "~/dataLayer";

export const isDev = process.env.NODE_ENV === "development";

export interface Config {
  r2AccountId: string;
  r2AccessKeyId: string;
  r2SecretAccessKey: string;
  r2Bucket: string;

  konCliPath: string;
  filesDir: string;
  apiUrl: string;
  apiKey: string;
}

export function getConfig(): Config {
  return {
    r2AccountId: process.env.R2_ACCOUNT_ID ?? "",
    r2AccessKeyId: process.env.R2_ACCESS_KEY_ID ?? "",
    r2SecretAccessKey: process.env.R2_SECRET_ACCESS_KEY ?? "",
    r2Bucket: "konbert-files",
    konCliPath: process.env.KON_CLI_PATH ?? "/usr/local/bin/kon",
    filesDir: process.env.KONBERT_FILES_DIR ?? "/tmp/konbert",
    apiUrl: process.env.KONBERT_API_URL ?? "http://localhost:4000/api/v1",
    apiKey: process.env.KONBERT_API_KEY ?? "anonymous",
  };
}

export function createDataLayer() {
  return new DataLayer("/api/v1");
}

export function createDataLayerV2() {
  return new DataLayer("/api/v2");
}
