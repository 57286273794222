import { FormatOptionsValues } from "~/components/FormatForm";
import type { InputOptions, QueryResponseData } from "~/.server/dataSource";

export interface File {
  file_id: string;
  size: number;
  name: string;
  read_options: InputOptions;
  upload_url: string;
}

export interface Run {
  id: string;
  finished: boolean;
  finished_at: string | null;
  inserted_at: string;
  output_file: File | null;
  output_file_id: string | null;
  output_file_content: string | null;
  error_message: string | null;
  error_details: string | null;
  started_at: string;
  status: "queued" | "completed" | "failed" | "running";
  step: "sqlify/convert";
}

export type Row = Record<string, unknown>;

export interface ApiErrorResponse<
  E extends Record<string, unknown> = Record<string, unknown>
> {
  status: "error";
  http_status: number;
  error: {
    type?: string;
    message: string;
    details?: E;
  };
  data: null;
}

export interface ApiOkResponse<T> {
  status: "ok";
  http_status: number;
  error: null;
  data: T;
}

export type ApiResponse<
  T,
  E extends Record<string, unknown> = Record<string, unknown>
> = ApiOkResponse<T> | ApiErrorResponse<E>;

export async function fetchApi<
  T,
  E extends Record<string, unknown> = Record<string, unknown>
>({
  url,
  method = "GET",
  body,
  headers: defaultHeaders,
  apiKey,
}: {
  url: string;
  method: string;
  headers?: Record<string, string>;
  body?: unknown;
  apiKey?: string;
}): Promise<ApiResponse<T, E>> {
  const contentTypeHeaders: HeadersInit =
    body === undefined || body instanceof FormData
      ? {}
      : { "Content-Type": "application/json" };

  const apiKeyHeaders: HeadersInit =
    apiKey === undefined ? {} : { Authorization: `Bearer ${apiKey}` };

  const headers = {
    ...defaultHeaders,
    ...contentTypeHeaders,
    ...apiKeyHeaders,
  };

  console.log("[DataLayer]", { url, method, headers, body });

  const response = await fetch(url, {
    method,
    headers: headers,
    body:
      body === undefined
        ? undefined
        : body instanceof FormData
        ? body
        : JSON.stringify(body),
  });

  const contentType = response.headers.get("content-type");
  const isJson = contentType && contentType.includes("application/json");

  const responseBody = isJson ? await response.json() : await response.text();

  if (response.status >= 400) {
    console.error("[DataLayer]", {
      request: { url, method, headers, body },
      response: {
        status: response.status,
        body: responseBody,
        headers: Object.fromEntries(response.headers.entries()),
      },
    });
  }

  if (!isJson) {
    return {
      http_status: response.status,
      status: "error",
      data: responseBody,
      error: {
        type: "unknown",
        message: "Unknown error",
        details: responseBody,
      },
    };
  }

  return { http_status: response.status, ...responseBody };
}

export type FileMetadata = Record<string, unknown>;
export type FileReadOptions = {
  format_id: string;
  [key: string]: string | boolean | null;
};

export class DataLayer {
  constructor(public baseUrl: string, private apiKey?: string) {}

  async createFile({
    name,
    size,
    url,
    data,
    metadata,
    read_options,
  }: {
    name?: string;
    url?: string;
    size?: number;
    data?: string;
    metadata?: FileMetadata;
    read_options: FileReadOptions;
  }) {
    return this.#createFile({
      size,
      http: url ? { url } : undefined,
      name,
      data,
      metadata,
      read_options,
    });
  }

  async createFileFromData(data: string) {
    return this.#createFile({
      data,
      apiKey: this.apiKey,
    });
  }

  async #createFile(body: unknown) {
    const response = await fetchApi<File>({
      url: `${this.baseUrl}/files`,
      method: "POST",
      body: body,
      apiKey: this.apiKey,
    });

    return response;
  }

  async updateFile(
    fileId: string,
    body: { read_options?: FileReadOptions; name?: string }
  ) {
    const response = await fetchApi<File>({
      url: `${this.baseUrl}/files/${fileId}`,
      method: "POST",
      body: body,
      apiKey: this.apiKey,
    });

    return response;
  }

  uploadFile({
    fileId,
    uploadUrl,
    file,
    notifyProgress,
  }: {
    fileId: string;
    uploadUrl: string;
    file: globalThis.File;
    notifyProgress: (progress: number) => void;
  }) {
    const xhr = new XMLHttpRequest();
    let isCancelled = false;

    xhr.open("PUT", uploadUrl, true);
    xhr.setRequestHeader("Content-Type", file.type);

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total) * 100;
        notifyProgress(percentComplete);
      }
    };

    const promise = new Promise<void>((resolve, reject) => {
      xhr.onload = () => {
        if (isCancelled) {
          reject(new Error("Upload cancelled"));
        } else if (xhr.status >= 200 && xhr.status < 300) {
          resolve();
        } else {
          reject(new Error(`HTTP error! status: ${xhr.status}`));
        }
      };

      xhr.onerror = () => {
        reject(new Error("Network error occurred"));
      };
    });

    xhr.send(file);

    return {
      promise,
      cancel: () => {
        isCancelled = true;
        xhr.abort();
      },
    };
  }

  async queryFile({
    fileId,
    query,
    history = "false",
  }: {
    fileId: string;
    query: string | null;
    history?: "true" | "false";
  }) {
    return await fetchApi<QueryResponseData>({
      url: `${this.baseUrl}/files/${fileId}/query`,
      method: query === null ? "GET" : "POST",
      body: query === null ? undefined : { query, history },
      apiKey: this.apiKey,
    });
  }

  async getFileQuerySuggestions({ fileId }: { fileId: string }) {
    return await fetchApi<string[]>({
      url: `${this.baseUrl}/files/${fileId}/suggestions`,
      method: "GET",
    });
  }

  async getFile(fileId: string) {
    return await fetchApi<File>({
      url: `${this.baseUrl}/files/${fileId}`,
      method: "GET",
      apiKey: this.apiKey,
    });
  }

  async convertFile({
    fileId,
    input,
    output,
  }: {
    fileId: string;
    input: {
      format: string;
      options: FormatOptionsValues;
    };
    output: {
      format: string;
      options: FormatOptionsValues;
    };
  }) {
    const response = await fetchApi<Run>({
      url: `${this.baseUrl}/convert`,
      method: "POST",
      body: {
        input: {
          file_id: fileId,
          ...input,
        },
        output,
      },
      apiKey: this.apiKey,
    });

    return response;
  }

  awaitRun({ runId, pollEveryMs }: { runId: string; pollEveryMs: number }) {
    let timer: ReturnType<typeof setTimeout> | null = null;

    const promise = new Promise<ApiResponse<Run>>((resolve) => {
      const check = async () => {
        const response = await fetchApi<Run>({
          url: `${this.baseUrl}/runs/${runId}`,
          method: "GET",
          apiKey: this.apiKey,
        });

        if (response.status === "ok") {
          if (
            response.data.status === "completed" ||
            response.data.status === "failed"
          ) {
            return resolve(response);
          }

          timer = setTimeout(check, pollEveryMs);
        }
      };

      check();
    });

    return {
      promise,
      cancel: () => {
        if (timer) {
          clearTimeout(timer);
        }
      },
    };
  }
}
